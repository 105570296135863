import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import generalClasses from "./general.module.css"
import SEO from "../../components/seo"

import displayImage from "../../images/case-studies/smeupturn.jpg"
import websiteImage from "../../images/case-studies/smeupturn-2.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

const SmeUpturn = props => (
  <HomePageLayout pageTitle="SME UPTURN" background>
    <SEO title="SME Upturn Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>SMEUPTURN</h1>
          <p className={generalClasses.caseStudyDescription}>
            SME Upturn is a one-stop online learning community for
            entrepreneurs.
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          <a
            className={generalClasses.caseStudyLink}
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.smeupturn.com"
          >
            VISIT WEBSITE
          </a>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>SME Upturn</p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Lagos, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1 - 50 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Learning</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>MEET SME UPTURN</h1>
        <p className={generalClasses.meetCaseStudyText}>
          SME Upturn is a community of small business owners taking matters into
          their own hands to ensure that their businesses outlives them! We
          support you to grow organically, to develop structures and processes
          that will ensure that your business outlives you.
        </p>
      </section>
      <section className={generalClasses.section}>
        <div>
          <h1 className={generalClasses.sectionHeader}>RESEARCH</h1>
          <p className={generalClasses.sectionText} style={{ width: "80%" }}>
            Our design team carried out a survey before starting the project and
            found out a lot of individuals are looking for jobs daily and
            platforms to learn a particular skill that would help them get a new
            job based on the results of the survey hence resulting in our team
            creating a website for our client on helping train individuals
            seeking new jobs.
          </p>
        </div>
      </section>
      <img
        src={displayImage}
        alt=""
        className={generalClasses.displayImage}
        style={{ margin: "80px 0" }}
      />
      <section className={generalClasses.section}>
        <h1 className={generalClasses.sectionHeader}>RESULT</h1>
        <p
          className={generalClasses.sectionText}
          style={{ width: "80%", marginBottom: "80px" }}
        >
          A beautifully crafted yet intuitive landing page was designed for the
          client to solve both users and business goals. A website that gives
          information about what SME Upturn is about and also a form for the
          users to fill.
        </p>
      </section>
      <img
        src={websiteImage}
        alt=""
        className={generalClasses.displayImageFull}
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/odirs"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Ondo State Internal Revenue Service
            </span>
          </p>
        </Link>
        <Link
          to="/case-studies/ondo-state-website"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Ondo State Website
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default SmeUpturn
